import React from "react"
import Layout from "../components/Layout"
import Accordion from "../components/ui/Accordion"
import Block from "../components/ui/Block"
import Button from "../components/ui/Button"
import Card from "../components/ui/Card"
import CTA from "../components/ui/CTA"
import FlexContainer from "../components/ui/FlexContainer"
import { graphql, Link } from "gatsby"
import Icon from "../components/ui/Icon"

export default function Formulare({ data }) {
  const pageContext = {
    seo: {
      title: "Turnverein Lahr",
      description:
        "Der Turnverein Lahr von 1846 e.V. zählt zu den ältesten Turnvereinen in Baden- Württemberg und ist mit nahezu 1700 Mitgliedern der größte Sport anbietende Verein in Lahr.",
      robots: ["index, follow"],
      path: "/downloads",
      lang: "de",
    },
  }
  const downloads = data?.allDatoCmsDownload.nodes
  return (
    <Layout pageContext={pageContext}>
      {/* <Block
        note="Downloads im Überblick"
        title="Formulare"
        // content="Hier findest du alle Sportarten, die wir aktuell anbieten. Wir unterteilen das Angebot in Wettkampf- und Freizeitsport."
      /> */}
      <Block title="Downloads" content="Downloads im Überblick" note="Wichtig">
        <div className="downloads-list">
          {downloads?.map(form => (
            <a target="_blank" className="download-item" href={form?.pdf?.url} download>
              {form?.title}
            </a>
          ))}
          <Link className="download-item" to="/formulare/">Formulare</Link>
        </div>
      </Block>
      <CTA />
    </Layout>
  )
}
export const query = graphql`
  query DownloadQuery {
    allDatoCmsDownload {
      nodes {
        pdf {
          alt
          url
        }
        title
      }
    }
  }
`
